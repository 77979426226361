type IConfigACL = {
  user: {
    create: 'user:create';
    update: 'user:update';
    list: 'user:read';
    delete: 'user:delete';
  };
  video: {
    create: 'video:create';
    update: 'video:update';
    delete: 'video:delete';
    list: 'video:read';
  };
  role: {
    create: 'role:create';
    update: 'role:update';
    list: 'role:read';
    delete: 'role:delete';
  };
  company: {
    detail: 'company:read';
    update: 'company:update';
    signature: 'company_signature:update';
  };
};

const configACL: IConfigACL = {
  user: {
    create: 'user:create',
    update: 'user:update',
    list: 'user:read',
    delete: 'user:delete',
  },
  video: {
    create: 'video:create',
    update: 'video:update',
    delete: 'video:delete',
    list: 'video:read',
  },
  role: {
    create: 'role:create',
    update: 'role:update',
    list: 'role:read',
    delete: 'role:delete',
  },
  company: {
    detail: 'company:read',
    update: 'company:update',
    signature: 'company_signature:update',
  },
};

export { configACL };
