import { Avatar, Box, Stack, Typography } from '@mui/material';
import interativaImg from 'common/assets/interativa.svg';
import logoImg from 'common/assets/logo-otimizada.png';
import logoImgVe from 'common/assets/logo-v-otimizada.png';

interface Props {
  size?: 'small' | 'medium';
}

const LayoutFooter = ({ size = 'medium' }: Props) => {
  const version = process.env.REACT_APP_VERSION;

  return (
    <Stack
      spacing={size === 'medium' ? 3 : 2}
      sx={{ marginX: 'auto', marginTop: '86px !important' }}
    >
      {size === 'medium' && (
        <>
          <Stack
            sx={{
              background: (theme) => `${theme.palette.primary.main}10`,
              borderRadius: 2,
              padding: 3,
              marginX: '16px !important',
            }}
          >
            <Box
              sx={{ fontWeight: 'bold', fontSize: '0.875rem', marginTop: 2 }}
            >
              <Avatar
                src={logoImg}
                alt='Video Especialista'
                sx={{
                  borderRadius: 0,
                  width: '100%',
                  height: '56px',
                  '& img': {
                    objectFit: 'contain',
                    objectPosition: 'center',
                  },
                }}
              />
            </Box>
          </Stack>

          <Box
            component='span'
            sx={{
              padding: 1,
              marginTop: 1,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <a href='#' target='_blank'>
              <img
                src={interativaImg}
                alt='Interativa Digital'
                height='36px'
                width={96}
              />
            </a>
            <Typography
              sx={{
                fontWeight: 500,
                color: 'secondary.main',
                fontSize: '0.875rem',
              }}
            >
              Versão {version}
            </Typography>
          </Box>
        </>
      )}

      {size === 'small' && (
        <>
          <Avatar
            src={logoImgVe}
            alt='Video Especialista'
            sx={{
              borderRadius: 0,
              width: '100%',
              height: '64px',
              '& img': {
                objectFit: 'contain',
                objectPosition: 'center',
              },
            }}
          />

          <Box
            component='span'
            sx={{
              padding: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <a href='#' target='_blank'>
              <img src={interativaImg} alt='Interativa Digital' height='24px' />
            </a>
            <Typography
              sx={{
                fontWeight: 500,
                color: 'secondary.main',
                fontSize: '0.875rem',
              }}
            >
              v{version}
            </Typography>
          </Box>
        </>
      )}
    </Stack>
  );
};

export { LayoutFooter };
