import { useCanCompany } from 'common/hooks/useCanCompany';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {
  component: JSX.Element;
  isPremium?: boolean;
};

export function CanRoute({ component, isPremium = false }: Props) {
  const location = useLocation();
  const isCompanyActive = useCanCompany();

  if (!localStorage.getItem('application.auth.token')) {
    // Redirecionar para página de login caso o usuário não esteja autenticado
    return <Navigate to='/login' state={{ from: location }} />;
  }

  if (isPremium && !isCompanyActive) {
    // Redirecionar para página home caso a empresa esteja inativa
    toast.error(
      'Oops! Sua empresa está bloqueada. Verifique a sua assinatura.'
    );

    return <Navigate to='/' state={{ from: location }} />;
  }
  return component;
}
