import { LinearProgress } from '@mui/material';
import { AuthLayout } from 'layouts/AuthLayout';
import { MuiLayout } from 'layouts/MuiLayout';
import { lazy, Suspense } from 'react';
import { Route, Routes as ContainerRoutes } from 'react-router-dom';
import { CanRoute } from './CanRoute';
import { NoRequireAuth } from './NoRequireAuth';
import { RequireAuth } from './RequireAuth';

const PageHowToUse = lazy(() => import('../modules/helper/pages/HowToUse'));
const PageAbout = lazy(() => import('../modules/helper/pages/About'));
const PageContact = lazy(() => import('../modules/helper/pages/Contact'));
const PageNotFound = lazy(() => import('../modules/error/pages/NotFound'));
const PageProfile = lazy(() => import('../modules/dashboard/pages/Profile'));
const PageHomeView = lazy(() => import('../modules/dashboard/pages/Home'));
const PageCompanyView = lazy(() => import('../modules/company/pages/Company'));
const PageUserList = lazy(() => import('../modules/user/pages/List'));
const PageRoleList = lazy(() => import('../modules/role/pages/List'));
const PageVideoList = lazy(() => import('../modules/video/pages/List'));
const PageVideoView = lazy(() => import('../modules/video/pages/View'));
const PageUserForm = lazy(() => import('../modules/user/pages/Form'));
const PageRoleForm = lazy(() => import('../modules/role/pages/Form'));
const PageAuthSignIn = lazy(() => import('../modules/auth/pages/SignIn'));
const PagePlan = lazy(() => import('../modules/signature/pages/Plan'));
const PageAuthResetPassword = lazy(
  () => import('../modules/auth/pages/ResetPassword')
);
const PageAuthRegister = lazy(() => import('../modules/auth/pages/Register'));
const PageAuthForgotPassword = lazy(
  () => import('../modules/auth/pages/ForgotPassword')
);

export function Routes() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ContainerRoutes>
        {/* Routes Protected */}
        <Route
          path='/'
          element={
            <RequireAuth>
              <MuiLayout />
            </RequireAuth>
          }
        >
          <Route index element={<PageHomeView />} />

          <Route
            path='/profile'
            element={<CanRoute component={<PageProfile />} />}
          />

          <Route
            path='/contact'
            element={<CanRoute component={<PageContact />} />}
          />

          <Route
            path='/about'
            element={<CanRoute component={<PageAbout />} />}
          />

          <Route
            path='/how-to-use'
            element={<CanRoute component={<PageHowToUse />} />}
          />

          <Route
            path='/company'
            element={<CanRoute component={<PageCompanyView />} />}
          />

          <Route
            path='/users'
            element={<CanRoute component={<PageUserList />} isPremium />}
          />
          <Route
            path='/users/create'
            element={<CanRoute component={<PageUserForm />} isPremium />}
          />
          <Route
            path='/users/:id'
            element={<CanRoute component={<PageUserForm />} isPremium />}
          />

          <Route
            path='/videos'
            element={<CanRoute component={<PageVideoList />} isPremium />}
          />

          <Route
            path='/roles'
            element={<CanRoute component={<PageRoleList />} isPremium />}
          />
          <Route
            path='/roles/create'
            element={<CanRoute component={<PageRoleForm />} isPremium />}
          />
          <Route
            path='/roles/:id'
            element={<CanRoute component={<PageRoleForm />} isPremium />}
          />
        </Route>

        {/* Routes Public */}
        <Route
          path='/'
          element={
            <NoRequireAuth>
              <AuthLayout />
            </NoRequireAuth>
          }
        >
          <Route path='/login' element={<PageAuthSignIn />} />
          <Route path='/register' element={<PageAuthRegister />} />
          <Route path='/plans' element={<PagePlan />} />
          <Route path='/forgot-password' element={<PageAuthForgotPassword />} />
          <Route path='/reset-password' element={<PageAuthResetPassword />} />
        </Route>

        <Route path='/:company/video/:videoId' element={<PageVideoView />} />

        <Route path='*' element={<PageNotFound />} />
      </ContainerRoutes>
    </Suspense>
  );
}
