import { Box, Stack, useMediaQuery } from '@mui/material';
import { ScrollTop } from 'common/components/ScrollTop';
import { Search } from 'common/components/Search';
import { useAuth } from 'common/hooks/useAuth';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutHeader } from './components/Header/Header';
import { LayoutMenu } from './components/Menu';
import { LayoutSidebarNavbar } from './components/Sidebar/Navbar/Navbar';
import { LayoutSidebarProfile } from './components/Sidebar/Profile/Profile';
import { LayoutSidebar } from './components/Sidebar/Sidebar';

const MuiLayout = () => {
  const { user, signOut } = useAuth();
  const isDesktop = useMediaQuery('(min-width:860px)');
  const isMobile = useMediaQuery('(max-width:650px)');

  const [toggleMenu, setToggleMenu] = React.useState(() => {
    const getToggleMenu = localStorage.getItem('toggleMenu');

    if (getToggleMenu) {
      return !!JSON.parse(getToggleMenu);
    }

    return true;
  });
  const [isLargeSidebar, setIsLargeSidebar] = React.useState(true);
  const [openMenuMobile, setOpenMenuMobile] = React.useState(false);

  React.useEffect(() => {
    if (isDesktop) {
      setIsLargeSidebar(toggleMenu);
    } else {
      setIsLargeSidebar(false);
    }
  }, [toggleMenu, isDesktop]);

  const handleToggleMenu = React.useCallback(() => {
    setToggleMenu(!toggleMenu);

    localStorage.setItem('toggleMenu', JSON.stringify(!toggleMenu));
  }, [toggleMenu]);

  const handleOpenMenuMobile = React.useCallback(() => {
    setOpenMenuMobile(true);
  }, []);

  const handleCloseMenuMobile = React.useCallback(() => {
    setOpenMenuMobile(false);
  }, []);

  console.clear();
  console.log(
    '%c🅅🄸🄳🄴🄾 🄴🅂🄿🄴🄲🄸🄰🄻🄸🅂🅃🄰',
    'color:#0890ba; background-color:transparent;font-size:48px'
  );
  console.log(
    `%cVersão do APP: v${process.env.REACT_APP_VERSION}\nDesenvolvido por: INTERATIVA DIGITAL`,
    'color:#0890ba; font-variant: small-caps; font-size: 14px;'
  );

  return (
    <>
      <Box display='flex' minHeight='100%'>
        {/* Menu Lateral */}
        {!isMobile && (
          <LayoutSidebar
            isLargeSidebar={isLargeSidebar}
            sx={
              !isDesktop ? { display: toggleMenu ? 'flex' : 'none' } : undefined
            }
          >
            <LayoutSidebarProfile user={user} showInfo={isLargeSidebar} />

            <LayoutSidebarNavbar visibleDescriptionGroup={isLargeSidebar} />
          </LayoutSidebar>
        )}

        {isMobile && (
          <LayoutMenu
            anchor='left'
            open={openMenuMobile}
            onClose={handleCloseMenuMobile}
            onOpen={handleOpenMenuMobile}
          />
        )}

        {/* Conteúdo Principal */}
        <Stack sx={{ flex: 1, padding: isMobile ? 0 : 3, maxWidth: '100vw' }}>
          {/* Cabeçalho da Página */}
          <LayoutHeader
            user={user}
            signOut={signOut}
            onToggleMenu={isMobile ? handleOpenMenuMobile : handleToggleMenu}
            isOpenMenu={isLargeSidebar}
            sx={{ marginBottom: isMobile ? 0 : 8 }}
          >
            <Search />
          </LayoutHeader>

          {/* Conteúdo da Página */}
          <Stack sx={{ padding: isMobile ? 3 : 0 }}>
            <Outlet />
          </Stack>
        </Stack>
      </Box>

      <ScrollTop />
    </>
  );
};

export { MuiLayout };
