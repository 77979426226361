/* eslint-disable react/prop-types */
import { Stack, StackProps, Typography } from '@mui/material';
import { useAuth } from 'common/hooks/useAuth';
import { useLocation } from 'react-router-dom';

const LayoutHeaderContent: React.FC<StackProps> = ({
  sx,
  children,
  ...rest
}) => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  return (
    <Stack
      direction='row'
      sx={{ backgroundColor: (theme) => theme.palette.background.paper, ...sx }}
      {...rest}
    >
      {pathname === '/' ? (
        <Typography
          variant='h6'
          noWrap
          sx={{
            fontWeight: 500,
            fontSize: ['1rem', '1.25rem'],
            marginX: ['auto', 0],
            width: '100%',
            maxWidth: 350,
          }}
        >
          Bem-vindo(a), {user?.name?.split(' ')?.[0]} 👋
        </Typography>
      ) : (
        children
      )}
    </Stack>
  );
};

export { LayoutHeaderContent };
