import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    gradient: {
      primary: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    gradient?: {
      primary?: string;
    };
  }
}

// A custom theme for this app
const theme = createTheme({
  gradient: {
    primary:
      'linear-gradient(141.64deg, rgb(38, 6, 107) 3.52%, rgb(14, 53, 81) 66.02%, rgb(5, 40, 71) 88.03%)',
  },
  typography: {
    allVariants: {
      color: '#2b2a2a',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0890ba',
    },
    secondary: {
      main: '#444a52',
      dark: '#2b2a2a',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f0f0f2',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          borderRadius: 8,
        },
        contained: {
          background: 'linear-gradient(45deg, #07617d 30%, #0890ba 90%)',
        },
        outlined: {
          borderWidth: 2,
          fontWeight: 'bold',
          ':hover': {
            borderWidth: 2,
            fontWeight: 'bold',
          },
          ':active': {
            borderWidth: 2,
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#3D4E69',
        },
        inputAdornedStart: {
          color: '#3D4E69',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: '100% !important',
        },
      },
    },
    // alterar cor icon de input
  },
});

export default theme;
