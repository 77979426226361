/* eslint-disable react/prop-types */
import { Stack, StackProps } from '@mui/material';
import { useAuth } from 'common/hooks/useAuth';
import { LayoutFooter } from '../Footer';
import { LayoutSidebarLogo } from './Logo';

type Props = StackProps & {
  isLargeSidebar: boolean;
};

const LayoutSidebar: React.FC<Props> = ({
  isLargeSidebar,
  children,
  sx,
  ...props
}) => {
  const { user } = useAuth();
  return (
    <Stack
      spacing={isLargeSidebar ? 4 : 2}
      sx={
        isLargeSidebar
          ? {
              width: 250,
              padding: 3,
              paddingRight: 0,
              transition: 'all 0.3s ease-in-out',
              ...sx,
            }
          : {
              width: 'auto',
              padding: 3,
              paddingRight: 0,
              paddingLeft: 0,
              transition: 'all 0.3s ease-in-out',
              ...sx,
            }
      }
      {...props}
    >
      {user?.company && (
        <LayoutSidebarLogo
          image={user.company.image}
          name={user.company.name}
          size={isLargeSidebar ? 'large' : 'small'}
        />
      )}

      {children}

      <LayoutFooter size={isLargeSidebar ? 'medium' : 'small'} />
    </Stack>
  );
};

export { LayoutSidebar };
