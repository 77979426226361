import {
  HiOutlineChatAlt2,
  HiOutlineInformationCircle,
  HiOutlineOfficeBuilding,
  HiOutlineQuestionMarkCircle,
  HiOutlineShieldCheck,
  HiOutlineUserCircle,
  HiOutlineUsers,
  HiOutlineVideoCamera,
  HiOutlineViewGrid,
} from 'react-icons/hi';
import { IconType } from 'react-icons/lib';
import { MdOutlineSettingsPower } from 'react-icons/md';
import { configACL } from './AccessControlList';

export type IMenuLink = {
  path: string;
  name: string;
  permissions?: string[];
  Icon: IconType;
  premium?: boolean;
  hasSearch?: boolean;
  subLinks?: Array<{
    path: string;
    name: string;
    permissions?: string[];
    Icon: IconType;
    premium?: boolean;
    hasSearch?: boolean;
  }>;
};

export type IMenu = {
  groupName: string;
  links: IMenuLink[];
};

export const menus: IMenu[] = [
  {
    groupName: 'Geral',
    links: [
      {
        path: '/',
        name: 'Painel de Controle',
        Icon: HiOutlineViewGrid,
      },
      {
        path: '/company',
        name: 'Empresa',
        permissions: [configACL.company.detail],
        Icon: HiOutlineOfficeBuilding,
      },
      {
        path: '/roles',
        name: 'Funções',
        permissions: [configACL.role.list],
        Icon: HiOutlineShieldCheck,
        premium: true,
      },
      {
        path: '/users',
        name: 'Usuários',
        permissions: [configACL.user.list],
        Icon: HiOutlineUsers,
        premium: true,
        hasSearch: true,
      },
      {
        path: '/videos',
        name: 'Vídeos',
        permissions: [configACL.video.list],
        Icon: HiOutlineVideoCamera,
        premium: true,
        hasSearch: true,
      },
    ],
  },
  {
    groupName: 'Configurações',
    links: [
      {
        path: '/profile',
        name: 'Perfil',
        Icon: HiOutlineUserCircle,
      },
    ],
  },
  {
    groupName: 'Sistema',
    links: [
      {
        path: '/how-to-use',
        name: 'Como usar',
        Icon: HiOutlineQuestionMarkCircle,
      },
      {
        path: '/contact',
        name: 'Contato',
        Icon: HiOutlineChatAlt2,
      },
      {
        path: '/about',
        name: 'Sobre',
        Icon: HiOutlineInformationCircle,
      },
      {
        path: '/sign-out',
        name: 'Sair',
        Icon: MdOutlineSettingsPower,
      },
    ],
  },
];
